import EventEmitter from "events";
import { RefObject } from "react";
import {
  PreviewCommLayer,
  PreviewCommListener,
  STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES,
  STUDIO_PREVIEW_MESSAGE_TYPES,
  StudioPreviewClientMessage,
  StudioPreviewClientMessages,
  StudioPreviewMessage,
} from "sutro-common/studio-interpreter-common-types";

import config from "~/app.config";

export class PreviewComm extends EventEmitter implements PreviewCommLayer {
  #listeners: Partial<{
    [K in STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES]: Array<PreviewCommListener<K>>;
  }> = {};
  #domain: string;
  iframeRef: RefObject<HTMLIFrameElement>;
  lastValues: StudioPreviewClientMessages;

  constructor(
    iframeRef: RefObject<HTMLIFrameElement>,
    domain = config.previewUrl
  ) {
    super();
    this.#domain = domain;
    this.iframeRef = iframeRef;
    this.lastValues = {};
    window.addEventListener("message", this.handleMessage);
  }

  /**
   * The `methodName = () =>{}` syntax binds the method to the instance at the moment of instantiation
   */
  sendMessage = (
    ...messages: StudioPreviewMessage<STUDIO_PREVIEW_MESSAGE_TYPES>[]
  ) => {
    const message = messages.length === 1 ? messages[0] : messages;

    this.iframeRef.current?.contentWindow?.postMessage(message, this.#domain);
  };

  onMessage<T extends STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES>({
    type,
    callback,
  }: {
    type: T;
    callback: PreviewCommListener<T>;
  }): void {
    const listeners: Array<PreviewCommListener<T>> | undefined =
      this.#listeners[type];

    if (listeners === undefined) {
      this.#listeners[type] = [];
    }

    this.#listeners[type]?.push(callback);
  }

  removeMessageListener<T extends STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES>({
    type,
    callback,
  }: {
    type: T;
    callback: PreviewCommListener<T>;
  }): void {
    const listeners: Array<PreviewCommListener<T>> | undefined =
      this.#listeners[type];
    if (listeners === undefined) {
      return;
    }

    //@ts-expect-error TS2322
    this.#listeners[type] = listeners.filter((cb) => cb !== callback);
  }

  private handleMessage = (
    event: MessageEvent<
      | StudioPreviewClientMessage<STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES>
      | StudioPreviewClientMessage<STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES>[]
    >
  ) => {
    if (event.origin !== this.#domain) {
      return;
    }
    const eventMessage = event.data;
    const messages = Array.isArray(eventMessage)
      ? eventMessage
      : [eventMessage];
    messages.forEach(
      <T extends STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES>(
        message: StudioPreviewClientMessage<T>
      ) => {
        this.lastValues[message.type as T] =
          message as StudioPreviewClientMessages[T];
        const listeners = this.#listeners[message.type] as Array<
          PreviewCommListener<typeof message.type>
        >;

        listeners?.forEach((listener) => {
          listener(message);
        });
      }
    );
    this.emit("message", this.lastValues);
  };

  cleanUp(): void {
    window.removeEventListener("message", this.handleMessage);
  }
}

// Exclusively so we can initialize as a non-null value at all times, even SSR
export class FakePreviewComm extends EventEmitter implements PreviewCommLayer {
  iframeRef: RefObject<HTMLIFrameElement>;
  lastValues: StudioPreviewClientMessages;

  constructor(iframeRef: RefObject<HTMLIFrameElement>) {
    super();
    this.iframeRef = iframeRef;
    this.lastValues = {};
  }

  /**
   * The `methodName = () =>{}` syntax binds the method to the instance at the moment of instantiation
   */
  sendMessage = () => {};

  onMessage(): void {}

  removeMessageListener(): void {}

  private handleMessage = () => {};

  cleanUp(): void {}
}
