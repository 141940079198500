import React, {
  PropsWithChildren,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  PreviewCommLayer,
  PreviewCommListener,
  STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES,
  StudioPreviewClientMessages,
} from "sutro-common/studio-interpreter-common-types";

import { FakePreviewComm, PreviewComm } from "./PreviewComm";

const fakeIframeRef: RefObject<HTMLIFrameElement> = { current: null };
const PreviewCommLayerContext = React.createContext<PreviewCommLayer>(
  new FakePreviewComm(fakeIframeRef)
);

/**
 *
 * This provider accepts an iframe reference and creates a Preview Comm layer
 * for sending messages to the preview iFrame.
 *
 * It will also clean up the PreviewComm object's event handlers on destruction
 */
export const PreviewCommLayerProvider: React.FC<
  PropsWithChildren<{
    // previewCommLayer: PreviewCommLayer
    iframeRef: RefObject<HTMLIFrameElement>;
  }>
> = ({
  children,
  iframeRef,
  // previewCommLayer
}): ReactNode => {
  const previewComm = useMemo(() => new PreviewComm(iframeRef), [iframeRef]);
  return (
    <PreviewCommLayerContext.Provider value={previewComm}>
      {children}
    </PreviewCommLayerContext.Provider>
  );
};

// The typing of this function fakes making the rest of the fields private.
// These are the only two that anyone should use from the context.
export function usePreviewCommLayer(): {
  sendMessage: PreviewCommLayer["sendMessage"];
  iframeRef: PreviewCommLayer["iframeRef"];
} {
  return useContext(PreviewCommLayerContext);
}

// Listeners should only be added through this hook so that they are destroyed
export function usePreviewCommCallback<
  T extends STUDIO_PREVIEW_CLIENT_MESSAGE_TYPES,
>(props: { type: T; callback: PreviewCommListener<T> }): void {
  const previewCommLayer = useContext(PreviewCommLayerContext);

  useEffect(() => {
    previewCommLayer?.onMessage(props);
    return () => {
      previewCommLayer?.removeMessageListener(props);
    };
  }, [previewCommLayer, props]);
}

export function usePreviewCommLastValues(): StudioPreviewClientMessages {
  const previewCommLayer = useContext(PreviewCommLayerContext);
  const [mostRecentMessage, setMostRecentMessage] =
    useState<StudioPreviewClientMessages>(previewCommLayer.lastValues);

  useEffect(() => {
    const handleMessage = (newMessage: StudioPreviewClientMessages) => {
      // new object ensures reactivity
      setMostRecentMessage({ ...newMessage });
    };

    previewCommLayer.addListener("message", handleMessage);

    return () => {
      previewCommLayer.removeListener("message", handleMessage);
    };
  }, [previewCommLayer]);

  return mostRecentMessage;
}
